import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import UpApi from "up-api";
import {useQuery, Message, useGlobal} from "up-form";
import {ResumeEnrol} from "./ResumeEnrol";
import {Alert} from "@material-ui/lab";
import ErrorRedirect from "../error/ErrorRedirect";
/**
 * Resume after creation of payment plan completion redirect from payment provider (e.g. debitSuccess)
 * @param DebitSuccessReference param contains an id created for completion
 */
const ResumeAferPaymentPlanComplete = ({...other}) => {
  const {DebitSuccessReference: paymentPlanId} = useQuery(),
    {t} = useTranslation();
  // redirecting from DebitSuccess, our session id must come from browser storage as not passed in url.
  const sessionId = sessionStorage.getItem("up.sessionId");
  const [{notified, notificationPending, notificationError} = {}, setSessionlessNotify] = useState();
  const {salesJourneyCRMId} = useGlobal();

  useEffect(() => {
    if (!sessionId && !notified && !(notificationPending || notificationError)) {
      /**
       * Special case here (DEV-1683) - we handle callback from Debit Success that hasn't been
       * initiated by this app. In this case there is no security context (sessionId) to perform an application update
       * so a narrower, insecure notification POST is used and the integration layer can reconcile
       * which account was set up using it's elevated security context (and hope that the accountId passed can't be used for nefarious means)
       */
      setSessionlessNotify({notificationPending: true});
      UpApi.debitSuccessNotification(salesJourneyCRMId, paymentPlanId)
        .then(() => setSessionlessNotify({notified: true}))
        .catch((error) => setSessionlessNotify({notified: true, notificationError: error}));
    }
  }, [sessionId, paymentPlanId, notified, notificationPending, notificationError, salesJourneyCRMId]);

  return (
    <>
      {!paymentPlanId && <ErrorRedirect i18nKey="ResumeEnrol.paymentPlanComplete.noCompletionId" />}
      {sessionId && paymentPlanId && (
        <ResumeEnrol
          resumeStep="paymentPlan"
          applicationUpdate={{
            payment: {
              paymentPlanId
            }
          }}
          {...other}
        >
          <Alert severity="success">{t("ResumeEnrol.paymentPlanComplete.text")}</Alert>
        </ResumeEnrol>
      )}
      {!sessionId &&
        notified &&
        (notificationError ? (
          <ErrorRedirect status="400" i18nKey="ResumeEnrol.paymentPlanComplete.notificationFailed" />
        ) : (
          <Redirect to="/content/debitSuccessPhoneComplete" />
        ))}
      <Message open={notificationPending} variant="busy" message={t("ResumeEnrol.busy")} />
    </>
  );
};

export default ResumeAferPaymentPlanComplete;
