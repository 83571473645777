/**
 * Form for Declaration step
 */
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import PropType from "prop-types";
import {useEffect, useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {BaseSection, Message, Skeletize, Typography, useSkeleton} from "up-form";
import {createDebitSuccessAccount} from "up-state";
import {ReactComponent as DebitSuccess} from "../../icons/debitsuccess.svg";

/**
{
  "successful": true,
  "templateUrl": "https://OC-Test.debitsuccess.com/DirectEntry/DirectDebitRequest/Form?templateid=8446bc42-265b-4196-9082-eb8c01b5e899",
  "errorMessage": [],
  "successMessage": [],
  "accountTemplateId": "8446bc42-265b-4196-9082-eb8c01b5e899"
}
 */
const PaymentPlan = ({form, section, change}) => {
  const {t} = useTranslation();
  const prefix = "Section.PaymentPlan";
  const pendingPreviousUpdates = useSkeleton();
  const {data: debitSuccessAccount, pending, error: debitSuccessError} = useSelector((state) => state.createDebitSuccessAccount);
  const {templateUrl} = debitSuccessAccount || {};
  const {
    pending: pendingUpdate,
    data: {opportunity: {opportunityCRMId: opportunityId} = {}, payment: {invoiceId, paymentPlanId} = {}} = {},
    error: applicationError
  } = useSelector((state) => state.application);
  const dispatch = useDispatch(),
    error = useMemo(
      () => debitSuccessError || applicationError || (!invoiceId && {statusCode: 422, message: "Invoice information not found"}),
      [debitSuccessError, applicationError, invoiceId]
    );

  useEffect(() => {
    if (!pendingPreviousUpdates && !pendingUpdate && opportunityId && invoiceId && !(paymentPlanId || debitSuccessAccount || pending || error)) {
      // Create account
      dispatch(createDebitSuccessAccount({opportunityId, invoiceId}));
    }
  }, [opportunityId, invoiceId, debitSuccessAccount, error, pending, dispatch, paymentPlanId, pendingPreviousUpdates, pendingUpdate]);

  /** hack to force update on submit as this will set the paymentPlanId 'field' correctly  */
  useEffect(() => {
    if (paymentPlanId) {
      change("hiddenDirtyField", true);
    }
  }, [paymentPlanId, change]);

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        {debitSuccessAccount && !paymentPlanId && (
          <Card variant="outlined" elevation={0}>
            <CardContent>
              <Grid container justifyContent="flex-end">
                <DebitSuccess style={{height: "5rem"}} />
              </Grid>
              <Typography variant="body1" component="span" paragraph>
                <Trans t={t} i18nKey={`${prefix}.setup.text`} />
              </Typography>
            </CardContent>
          </Card>
        )}
        {paymentPlanId && (
          <Typography variant="body1" gutterBottom>
            {t(`${prefix}.created`)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Skeletize variant="rectangle">
          <Button disabled={!templateUrl} color="primary" variant="contained" href={templateUrl} data-name="PaymentPlan.proceed">
            {t(`${prefix}.setup.label`)}
          </Button>
        </Skeletize>
      </Grid>
      <Message open={pending || pendingUpdate} variant="busy" message={t(`${prefix}.busy`)} />
      {error && <Message open={!!error} variant="error" message={error.message} />}
    </BaseSection>
  );
};

export default PaymentPlan;

PaymentPlan.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({paymentPlanId}) {
  return {
    payment: {
      paymentPlanId
    }
  };
}

export function mapFromApplication({payment: {paymentPlanId} = {}}) {
  return {
    paymentPlanId
  };
}
