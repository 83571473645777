/**
 * Convert form fields to UP API payloads for the MVP10 App version
 */
import {merge} from "lodash";

/**
 * Create a map of dynamically loaded modules by section name. Each module supplies 2-way mapping functions between the set of fields
 * associated with that section and one or more API entity types (in MVC parlance these would be controller view->model mappings). In our
 * simple case we are always using the same model with the backend so we just embed the mappings in the view modules (which are react components) to keep
 * them together, this is net enforced though.
 * Mapping functions are always of the form map[From|To][Entity Name] and accept the section being mapped and other conecxtual data
 */
const mappers = {};
Object.entries({
  acceptPayment: "AcceptPayment",
  address: "Address",
  chooseCourse: "ChooseCourse",
  details: "StudentDetails",
  digitalSignature: "DigitalSignature",
  checkout: "Checkout",
  disability: "Disability",
  employment: "Employment",
  languageAndCulture: "LanguageAndCulture",
  paymentPlan: "PaymentPlan",
  qualifications: "Qualifications",
  schooling: "Schooling",
  signup: "SignUp",
  studyReason: "Reason",
  termsAndConditions: "TermsAndConditions",
  usi: "USI"
}).forEach(([name, module]) => {
  import(`../../section/${module}`)
    .then((module) => {
      mappers[name] = module;
      console.debug(
        `Mappings loaded for ${name}: ${Object.keys(module)
          .filter((f) => /^map(From|To).+$/.test(f))
          .join()}`
      );
    })
    .catch((error) => console.error(`Module not loaded for ${name}`, error));
});

/**
 * Map form fields to a model object
 * Using the named mapping function call it for all the given sections which support it, merging the
 * returned value for each in turn with the given initial value to return a pupoulated model object
 * @param  {string} mapperName name of mapping function
 * @param  {Object} sections ,ap of section:field map
 * @param  {Object} initial intial data to seed return model with
 * @param  {*} other fowarded to mapping function
 */
function mapTo(mapperName, sections = {}, initial, other) {
  const rv = Object.entries(sections)
    .filter(([sectionName, section]) => !!sectionName)
    .map(([sectionName, section]) => {
      const {[sectionName]: {[mapperName]: mapper} = {}} = mappers;
      return mapper && mapper(section || {}, other);
    })
    .reduce((acc, result) => merge(acc, result || {}), initial);
  return rv;
}
/**
 * Map a model object to form fields
 * Given a model object pass to each section's mapping function in turn, and collect the completed sections
 * a map of section fields keyed by name
 * @param  {string} mapperName name of mapping function
 * @param  {} model model
 * @param  {} other passing thru metadata etc to mappers
 */
function mapFrom(mapperName, model, other) {
  const rv = Object.entries(mappers)
    .map(([sectionName, mappers]) => {
      const mapper = mappers[mapperName];
      return [sectionName, mapper && mapper(model, other)];
    })
    .filter(([sectionName, result]) => !!sectionName && !!result)
    .reduce((acc, [sectionName, result]) => ({[sectionName]: result, ...acc}), {});
  return rv;
}

export function mapFormToLead(sections, initial, other) {
  return mapTo("mapToLead", sections, initial, other);
}
export function mapFormToApplication(sections, initial, other) {
  return mapTo("mapToApplication", sections, initial, other);
}
export function mapApplicationToForm(application, other) {
  return mapFrom("mapFromApplication", application, other);
}
