/**
 * Main stepped form for Online Enrolment
 * Defines contents of each step (depending on context e.g. provider)
 * form-wide logic for switching beteen steps and inter-step submission
 */
import {alpha, Snackbar, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { isEmpty, merge} from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {formValueSelector, reduxForm} from "redux-form";
import {getUtmParams, page} from "up-analytics";
import UpApi from "up-api";
import {BaseEnrolmentForm, fromId, fromLabel, Message, toCourseIntake, toId, toLabel, useGlobal, useExcludedSet} from "up-form";
import {
  createApplication,
  createLead,
  createOrder,
  supportingDocuments,
  updateApplication,
  useCourses,
  useMetadata
} from "up-state";
import {isPortfolioRequired} from "../../business/enrol";
import ErrorRedirect from "../error/ErrorRedirect";
import AcceptPayment from "../section/AcceptPayment";
import Address from "../section/Address";
import Checkout from "../section/Checkout";
import ChooseCourse from "../section/ChooseCourse";
import Complete from "../section/Complete";
import DigitalSignature from "../section/DigitalSignature";
import Disability from "../section/Disability";
import Employment from "../section/Employment";
import LanguageAndCulture from "../section/LanguageAndCulture";
import PaymentPlan from "../section/PaymentPlan";
import Qualifications from "../section/Qualifications";
import Reason from "../section/Reason";
import Review from "../section/Review";
import Schooling from "../section/Schooling";
import SignUp from "../section/SignUp";
import StudentDetails from "../section/StudentDetails";
import SupportingDocuments from "../section/SupportingDocuments";
import TermsAndConditions from "../section/TermsAndConditions";
import USI from "../section/USI";
import ApplicationSummary from "./ApplicationSummary";
import {mapApplicationToForm, mapFormToApplication, mapFormToLead} from "./mapping/mapOnlineForm";
import {Typography, useQuery} from "up-form";

const formName = "enrolment";
const statusLocation = "Online Learning";
let inEnquiry = true;
let inSignUp = false;
let formSig;
let formAgreement;

const OnlineEnrolmentForm = reduxForm({
  form: formName,
  destroyOnUnmount: false
})(
  withStyles(
    (theme) => {
      const summaryAtSide = theme.breakpoints.up("sm");
      return {
        form: {
          [summaryAtSide]: {
            maxWidth: "80%"
          }
        },
        summary: {
          [summaryAtSide]: {
            position: "fixed",
            transform: "scale(0.6)",
            bottom: "1rem",
            right: theme.spacing(1),
            alignSelf: "flex-start",
            zIndex: 5,
            borderRadius: "2px",
            backgroundColor: alpha(theme.palette.background.paper, 0.7)
          }
        }
      };
    },
    {name: "UpOnlineEnrolmentForm"}
  )(({form, change, dirty, leadId, classes,inprogress , ...props}) => {
    const {provider, groups = []} = useGlobal();
    const {data: courses} = useCourses();
    const {data: metadata, data: {paymentMethods, statuses, statusReasons, payNowOrLaterOptions} = {}} = useMetadata();
    const dispatch = useDispatch();
    const theme = useTheme();
    const selector = formValueSelector(form);
    const {t} = useTranslation();
    const {data: application} = useSelector((state) => state.application || {});
    const {data: createLeadResponse} = useSelector((state) => state.createLead || {});
    const {data: {invoiceNumber} = {}} = useSelector((state) => state.createOrder || {});
    const {leadCRMId = leadId} = createLeadResponse || {}; // lead Id from previus lead explicit create
    const email = useSelector((state) => selector(state, "signup.emailAddress"));

    const stateprodId = useSelector((state) => selector(state, "chooseCourse.course.productId"));
    const [existingIdentityWarning, setExistingIdentityWarning] = useState(true);
    const [error, setError] = useState(null);
    const allButtons = {prev: {}, reset: {}, next: {}};
    let {
      opportunity: {opportunityCRMId: opportunityId, intakeCRMId, signatureName: applicationSignature} = {},
      student: {genderId} = {},
      payment: {firstPaymentTransactionId, payNowOrLaterId, methodId, paymentPlanId, invoiceId = invoiceNumber} = {},
      education: {studyReasonId} = {},
      declaration: {agreeTerms} = {}
    } = application || {};
    const [verificationRequired, setVerificationRequired] = useState(false);
    const [activity, setActivity] = useState();
    let {course} = (courses && intakeCRMId && toCourseIntake({intakeCRMId}, courses)) || {};
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    if(!course && leadCRMId) {
      let {pid: queryproductId} = useQuery();
      let productId = queryproductId || stateprodId;
      const filteredCourses = courses.filter(course => course.productId === productId);
      course = filteredCourses[0];
    }
    const isPaymentPlan = paymentMethods && methodId && /Plan/.test(toLabel(fromId(paymentMethods, methodId)) || "");
    
    const formInstallmentMethod = useSelector((state) => selector(state, "checkout.installmentMethod"));
    const formPaymentProvider = useSelector((state) => selector(state, "checkout.paymentProvider"));
    const formPaymentPlanFrequency = useSelector((state) => selector(state, "checkout.paymentPlanFrequency"));
    const formPaymethod = useSelector((state) => selector(state, "checkout.method"));
    const isAlternativePaymentPlan = paymentMethods && methodId && /Alternative/.test(toLabel(fromId(paymentMethods, methodId)) || "");
    const {isHEOrVETFunded} = course || {};
    const isIAH = "iahealth" === provider;
    const isPaymentSelected = 
        (!!formPaymethod && Object.keys(formPaymethod).length > 0 && ((formPaymethod?.id !== "809730001" || formPaymentProvider?.id === "809730001") || isIAH )) || (!!formPaymethod && (formPaymethod?.id === "809730001" && 
        (!!formInstallmentMethod && Object.keys(formInstallmentMethod).length > 0) &&
        (!!formPaymentProvider && Object.keys(formPaymentProvider).length > 0) &&
        (!!formPaymentPlanFrequency && Object.keys(formPaymentPlanFrequency).length > 0)));
    const isPaymentDeferred =
      (payNowOrLaterId && payNowOrLaterOptions && payNowOrLaterId === toId(fromLabel(payNowOrLaterOptions, "Pay Later"))) ||
      isHEOrVETFunded || (course && course.isHEOrVETFunded);  
    const excluded = useExcludedSet();
   

   
    const createOrUpdateApplication = useCallback(
      
      ({sections, extra = {}}) => {
      
        if (opportunityId && !inprogress) {
          // Application already successfully created
          if (dirty) {
            
        
            // form edited - update with a new version
            const newApplication = mapFormToApplication(sections, extra || {}, {
              metadata
            });

            Object.keys(newApplication).forEach(key => newApplication[key] === undefined && delete newApplication[key])

            if(isIAH && (newApplication.hasOwnProperty("declaration") || formSig || agreeTerms)) {
              let tempSignature = newApplication?.opportunity?.signatureName ?  newApplication?.opportunity?.signatureName :  agreeTerms ? applicationSignature : null;
              let tempAgreement = newApplication?.declaration?.agreeTerms ? newApplication?.declaration?.agreeTerms : agreeTerms ? agreeTerms : null;

              formSig = tempSignature !== undefined ? tempSignature  : formSig;
              formAgreement = tempSignature !== undefined ? tempAgreement : formAgreement;

              newApplication.opportunity = {};
              newApplication.opportunity.signatureName = {};
              newApplication.opportunity.signatureName = formSig;
              newApplication.declaration = {};
              newApplication.declaration.agreeTerms = {}; 
              newApplication.declaration.agreeTerms = formAgreement;
            }

            return dispatch(
              updateApplication({
                opportunityId,
                body: newApplication
              })
            );
          } else {
            console.debug("No update needed to application");
            return Promise.resolve(true);
          }
        } else if (leadCRMId && !inprogress) {
          // POST a new application
          return dispatch(
            createApplication(
              mapFormToApplication(
                {...sections},
                merge(
                  {
                    opportunity: {
                      statusLocationId: toId(fromLabel(metadata.statusLocations, statusLocation)),
                      intakeCRMId,
                      leadCRMId
                    }
                  },

                  
                  extra
                ),
                {metadata}
              )
            )
          ).then((newApplication) => {
            const {
              value: {opportunity: {sessionJwt} = {}}
            } = newApplication;
            if (sessionJwt) {
              // we have an initial auth token as it's a new application (no previous application under this identity)
              UpApi.configure({accessToken: sessionJwt});
              console.log("Initial application, verification not needed");
            } else {
              console.log("Application already made under this identity, verification needed");
              setVerificationRequired(true);
            }
            return newApplication;
          });
        } else {
          console.error("Cannot create application (need leadCRMId)");
        }
      },
      [opportunityId, dirty, dispatch, leadCRMId, metadata, intakeCRMId,agreeTerms, inprogress, isIAH, applicationSignature, ]
    );
    const createOrUpdateLead = useCallback(
      ({sections}) => {
        if (dirty || !leadCRMId) {
          return dispatch(
            createLead(
              mapFormToLead(
                sections,
                {
                  providerId: provider,
                  provider,
                  ...getUtmParams()
                },
                {metadata}
              )
            )
          );
        } else console.debug("Using previosly created lead: ", leadCRMId);
      },
      [dirty, dispatch, provider, metadata, leadCRMId]
    );

    const steps = isIAH ? 
    [
      !leadId && {
        // skip if leadId provided
        name: "signup",
        label: t("EnrolmentForm.signup.label"),
        reset: {},
        next: {},
        sections: {
          signup: {component: SignUp, course}
        },
        completed: leadCRMId || opportunityId,
        submit: ({signup}, state, step) => {
          inSignUp = true;
          createOrUpdateLead({
            sections: {signup},
            metadata,
            state,
            step
          })
        }
      },
      {
        // skip if course provided
        name: "chooseCourse",
        label: t("EnrolmentForm.chooseCourse.label"),
        reset: {},
        next: {},
        sections: {
          chooseCourse: ChooseCourse
        },
        completed: (!!course && !inEnquiry && opportunityId && inSignUp) || (!!course && inEnquiry && !inSignUp),
        canSubmit: () => leadCRMId,
        submit: ({chooseCourse}, state, step) => createOrUpdateApplication({sections: {chooseCourse}})
      },
      {
        name: "studentDetails",
        label: t("EnrolmentForm.studentDetails.label"),
        reset: {},
        next: {},
        completed: !!genderId, // gender required
        canSubmit: () => !!opportunityId,
        sections: {
          address: Address,
          details: StudentDetails
        },
        submit: ({address, details, signup}, state, step) => createOrUpdateApplication({sections: {address, details}})
      },
      isPortfolioRequired(provider) && {
        name: "supportingDocuments",
        prev: {},
        next: {},
        label: t("EnrolmentForm.supportingdocuments.label"),
        sections: {
          supportingDocuments: SupportingDocuments
        },
        submit: (form) => {
          return (
            form.supportingDocuments &&
            form.supportingDocuments.files &&
            form.supportingDocuments.files.length > 0 &&
            opportunityId &&
            dispatch(supportingDocuments(form.supportingDocuments.files, opportunityId)).then((result) => {
              change("supportingDocuments.files", []);
              return result;
            })
          );
        }
      },
      groups.includes("avetmiss") && {
        next: {},
        reset: {},
        completed: !!studyReasonId, // just using a required field
        name: "applicationDetails",
        label: t("EnrolmentForm.applicationDetails.label"),
        sections: {
          intro: (
            <Typography key={0} variant="caption">
              <br />
              {t("EnrolmentForm.applicationDetails.intro")}
            </Typography>
          ),
          languageAndCulture: LanguageAndCulture,
          disability: Disability,
          schooling: Schooling,
          qualifications: Qualifications,
          employment: Employment,
          usi: USI,
          studyReason: Reason
        },
        submit: ({languageAndCulture, disability, schooling, qualifications, employment, usi, studyReason}, state, step) =>
          createOrUpdateApplication({
            sections: {
              languageAndCulture,
              disability,
              schooling,
              qualifications,
              employment,
              usi,
              studyReason
            }
          }).then(() => {
            if (!isHEOrVETFunded && !isIAH) {
              // DEV-7154 (sic!) only on condition of not isHEOrVETFunded
              // DEV-1754: we do a POST submit update to change the statusReason for the benefit of the back-end workflow
              // has to be done as a separate update to make sure contact changes are done before opportunity
              dispatch(
                updateApplication({
                  opportunityId,
                  body: {
                    opportunity: {
                      statusId: statuses && toId(fromLabel(statuses, "Open")),
                      statusReasonId: statusReasons && toId(fromLabel(statusReasons, "Conditional Offer"))
                    }
                  }
                })
              );
            }
          })
      },
      {
        name: "termsAndConditions",
        ...allButtons,
        label: t("EnrolmentForm.termsAndConditions.label"),
        completed: agreeTerms || (isIAH &&  invoiceId),
        sections: {
          review: Review,
          termsAndConditions: TermsAndConditions,
          digitalSignature: DigitalSignature
        },
        submit: ({termsAndConditions, digitalSignature}, state, step) =>
          createOrUpdateApplication({
            sections: {termsAndConditions, digitalSignature}
          })
      },
      {
        name: "checkout",
        ...allButtons,
        label: t("EnrolmentForm.checkout.label"),
        completed: !!invoiceId,
        canSubmit: () => isPaymentSelected && opportunityId,
        sections: {
          checkout: Checkout
        },
        submit: ({checkout,termsAndConditions, digitalSignature}) =>
          createOrUpdateApplication({sections: {checkout},termsAndConditions, digitalSignature}).then(
            () =>
              dispatch(createOrder(opportunityId)).catch((error) =>
                setError({...error, message: "An order is already in progress"})
              ) // can fail 409 if invoice aleady created
          ),
        disabled: isPaymentDeferred
      },
      {
        name: "acceptPayment",
        label: t(`EnrolmentForm.acceptPayment.${isPaymentPlan ? "deposit" : "full"}.label`),
        completed: !!firstPaymentTransactionId || isAlternativePaymentPlan,
        sections: {
          acceptPayment: AcceptPayment
        },
        canSubmit: () => !!firstPaymentTransactionId,
        disabled: isPaymentDeferred
      },
      {
        name: "paymentPlan",
        label: t("EnrolmentForm.paymentPlan.label"),
        completed: paymentPlanId && isPaymentPlan,
        sections: {
          paymentPlan: PaymentPlan
        },
        pickSections: ["payment"],
        canSubmit: () => !!paymentPlanId,
        disabled: !isPaymentPlan || isPaymentDeferred
      },
      {
        name: "complete",
        label: t("EnrolmentForm.complete.label"),
        sections: {
          complete: Complete
        }
      }
    ].filter((v) => !!v)
    .map((step) => ({
      ...step,
      sections: Object.entries(step.sections).reduce(
        (acc, [name, section]) => ({
          ...acc,
          ...(!excluded.has(`OnlineEnrolmentForm.step.${step.name}.${name}`) && {[name]: section})
        }),
        {}
      )
    })).filter(({name, sections}) => !excluded.has(`OnlineEnrolmentForm.step.${name}`) && !isEmpty(sections)) 
    : [
      !leadId && {
        // skip if leadId provided
        name: "signup",
        label: t("EnrolmentForm.signup.label"),
        reset: {},
        next: {},
        sections: {
          signup: {component: SignUp, course}
        },
        completed: leadCRMId || opportunityId,
        submit: ({signup, chooseCourse}, state, step) =>
          createOrUpdateLead({
            sections: {signup, chooseCourse},
            metadata,
            state,
            step
          })
      },
      {
        // skip if course provided
        name: "chooseCourse",
        label: t("EnrolmentForm.chooseCourse.label"),
        reset: {},
        next: {},
        sections: {
          chooseCourse: ChooseCourse
        },
        completed: !!course,
        submit: ({chooseCourse}, state, step) => createOrUpdateApplication({sections: {chooseCourse}})
      },
      {
        name: "checkout",
        ...allButtons,
        label: t("EnrolmentForm.checkout.label"),
        completed: !!invoiceId,
        sections: {
          checkout: Checkout
        },
        canSubmit: () => isPaymentSelected && opportunityId,
        submit: ({checkout}) =>
          createOrUpdateApplication({sections: {checkout}}).then(
            () =>
              dispatch(createOrder(opportunityId)).catch((error) =>
                setError({...error, message: "An order is already in progress"})
              ) // can fail 409 if invoice aleady created
          ),
        disabled: isPaymentDeferred
      },
      {
        name: "acceptPayment",
        label: t(`EnrolmentForm.acceptPayment.${isPaymentPlan ? "deposit" : "full"}.label`),
        completed: !!firstPaymentTransactionId || isAlternativePaymentPlan,
        sections: {
          acceptPayment: AcceptPayment
        },
        canSubmit: () => !!firstPaymentTransactionId,
        disabled: isPaymentDeferred
      },
      {
        name: "studentDetails",
        label: t("EnrolmentForm.studentDetails.label"),
        reset: {},
        next: {},
        completed: !!genderId, // gender required
        sections: {
          address: Address,
          details: StudentDetails
        },
        submit: ({address, details, signup}, state, step) => createOrUpdateApplication({sections: {address, details}})
      },
      isPortfolioRequired(provider) && {
        name: "supportingDocuments",
        prev: {},
        next: {},
        label: t("EnrolmentForm.supportingdocuments.label"),
        sections: {
          supportingDocuments: SupportingDocuments
        },
        submit: (form) => {
          return (
            form.supportingDocuments &&
            form.supportingDocuments.files &&
            form.supportingDocuments.files.length > 0 &&
            opportunityId &&
            dispatch(supportingDocuments(form.supportingDocuments.files, opportunityId)).then((result) => {
              change("supportingDocuments.files", []);
              return result;
            })
          );
        }
      },
      {
        name: "paymentPlan",
        label: t("EnrolmentForm.paymentPlan.label"),
        completed: paymentPlanId && isPaymentPlan,
        sections: {
          paymentPlan: PaymentPlan
        },
        pickSections: ["payment"],
        canSubmit: () => !!paymentPlanId,
        disabled: !isPaymentPlan || isPaymentDeferred
      },
      groups.includes("avetmiss") && {
        next: {},
        reset: {},
        completed: !!studyReasonId, // just using a required field
        name: "applicationDetails",
        label: t("EnrolmentForm.applicationDetails.label"),
        sections: {
          intro: (
            <Typography key={0} variant="caption">
              <br />
              {t("EnrolmentForm.applicationDetails.intro")}
            </Typography>
          ),
          languageAndCulture: LanguageAndCulture,
          disability: Disability,
          schooling: Schooling,
          qualifications: Qualifications,
          employment: Employment,
          usi: USI,
          studyReason: Reason
        },
        submit: ({languageAndCulture, disability, schooling, qualifications, employment, usi, studyReason}, state, step) =>
          createOrUpdateApplication({
            sections: {
              languageAndCulture,
              disability,
              schooling,
              qualifications,
              employment,
              usi,
              studyReason
            }
          }).then(() => {
            if (!isHEOrVETFunded && !isIAH) {
              // DEV-7154 (sic!) only on condition of not isHEOrVETFunded
              // DEV-1754: we do a POST submit update to change the statusReason for the benefit of the back-end workflow
              // has to be done as a separate update to make sure contact changes are done before opportunity
              dispatch(
                updateApplication({
                  opportunityId,
                  body: {
                    opportunity: {
                      statusId: statuses && toId(fromLabel(statuses, "Open")),
                      statusReasonId: statusReasons && toId(fromLabel(statusReasons, "Conditional Offer"))
                    }
                  }
                })
              );
            }
          })
      },
      {
        name: "termsAndConditions",
        ...allButtons,
        label: t("EnrolmentForm.termsAndConditions.label"),
        completed: agreeTerms,
        sections: {
          review: Review,
          termsAndConditions: TermsAndConditions,
          digitalSignature: DigitalSignature
        },
        submit: ({termsAndConditions, digitalSignature}, state, step) =>
          createOrUpdateApplication({
            sections: {termsAndConditions, digitalSignature}
          })
      },
      {
        name: "complete",
        label: t("EnrolmentForm.complete.label"),
        sections: {
          complete: Complete
        }
      }
    ].filter((v) => !!v)
    .map((step) => ({
      ...step,
      sections: Object.entries(step.sections).reduce(
        (acc, [name, section]) => ({
          ...acc,
          ...(!excluded.has(`OnlineEnrolmentForm.step.${step.name}.${name}`) && {[name]: section})
        }),
        {}
      )
    })) // remove unneeded sections from config
    .filter(({name, sections}) => !excluded.has(`OnlineEnrolmentForm.step.${name}`) && !isEmpty(sections)) // remove excluded or empty steps from config;

    // if application data present set initialState null initially so useEffect will map to fields, else just empty
    const [initialState, setInitialState] = useState(application ? null : {});
    useEffect(() => {
      // Resuming form session with application state already loaded (need courses to check we have a valid intake and set course info)
      if (!initialState && courses && metadata) {
        try {
          setInitialState(mapApplicationToForm(application, {courses, metadata}));
          console.debug("Initialized from:", application);
        } catch (error) {
          console.debug(error.message);
          setError(error);
        } // catches mismatched backend course data
      }
    }, [courses, application, initialState, metadata]);

    return (
      <>
        {error && <ErrorRedirect {...error} />}
        {verificationRequired && <Redirect to={`/verification`} />}
        {application && application.isExistingIdentity && (
          <Snackbar open={existingIdentityWarning} autoHideDuration={6000} onClose={() => setExistingIdentityWarning(false)}>
            <Alert onClose={() => setExistingIdentityWarning(false)} severity="info">
              {t("EnrolmentForm.existingUserWarning.text", {
                email
              })}
            </Alert>
          </Snackbar>
        )}
        {isDesktop && <ApplicationSummary className={classes.summary} form={form} title={activity} expandUp />}
        {initialState && metadata ? (
          <BaseEnrolmentForm
            initialState={initialState}
            form={form}
            {...{change, dirty}}
            {...props}
            inprogress ={inprogress}
            steps={steps}
            onChangeActiveStep={({name, label}) => {
              page("Application Form Step", label, {
                leadCRMId,
                opportunityId,
                intakeCRMId,
                email
              });
              setActivity(t(`EnrolmentForm.${name}.activity`));
              window.history.replaceState(null, name, `/enrolling#${name}`);

            }}
            
          />
         
        ) : (
          <Message open variant="busy" message="Loading..." />
        )}

      </>
    );
  })
);
OnlineEnrolmentForm.propTypes = {};

export default OnlineEnrolmentForm;